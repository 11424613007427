<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Pembayaran Piutang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Akun Perkiraan </label>
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Masukkan Akun Perkiraan"
                v-model="formModal.nama"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nilai </label>
              <div class="w-100">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Rp</div>
                  </div>
                  <input
                    type="text"
                    class="form-control text-right"
                    :readonly="!isEditable"
                    id="inlineFormInputGroupUsername"
                    placeholder="Masukan Nilai"
                    v-money="configMoney"
                    v-model.lazy="formModal.nilai"
                  />
                </div>
                <div class="form-error" v-if="isAkunBank && piutangPajak.sisa_pajak > 0">
                  Nilai sudah termasuk piutang pajak
                </div>
              </div>
            </div>

            <div class="form-group d-flex align-items-center" v-if="false">
              <label class="form-label mt-3">Keterangan</label>
              <textarea
                rows="3"
                placeholder="Masukan Keterangan"
                v-model="formModal.keterangan"
                class="form-control"
              ></textarea>
            </div>
            <!-- <div class="form-group d-flex align-items-center">
                            <label class="form-label">Departemen </label>
                            <Select2 v-model="Departemen" :options="optionDepartemen" style="width:100% !important"
                                placeholder="Pilih Departemen" :settings="{}" @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)" />
                        </div> -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveDetail"
              :disabled="!isEditable"
              class="btn btn-save"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="" v-if="!kodeProject">
              <h4 class="title">Pembayaran Piutang Usaha</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-credit">Piutang Usaha</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'DetailCredit', params: { id: this.id } }"
                      >Detail Piutang Usaha</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Pembayaran
                  </li>
                </ol>
              </nav>
            </div>
            <div class="" v-else>
              <h4 class="title">Pembayaran Piutang Project</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'DataProject' }"
                      >Project</router-link
                    >
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{
                        name: 'DetailProject',
                        params: { kode: this.kodeProject },
                      }"
                      >Detail Project</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Pembayaran Invoice
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Pembayaran Piutang Usaha</div>
                <div class="sub-title">Edit informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Nomor Invoice</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="formData.no_invoice"
                    placeholder="Nomor Invoice"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Akun Piutang</label>
                  <input
                    type="text"
                    disabled
                    :value="bebanAkun.nama"
                    class="form-control"
                    placeholder="Akun Piutang"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Sisa Pembayaran</label>
                  <input
                    type="text"
                    disabled
                    :value="formatMoney(sisa_pembayaran)"
                    class="form-control text-right"
                    placeholder="Sisa Pembayaran"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Tanggal Pembayaran :</label>
                  <Datepicker
                    :autoApply="true"
                    :class="{
                      'is-invalid': formError && formError.tanggal_pembayaran,
                    }"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_pembayaran"
                    placeholder="Tanggal Pembayaran"
                    :format="format"
                    :enableTimePicker="false"
                    :minDate="maxTanggal.min"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    @cleared="changeDate('', 'tanggal_pembayaran')"
                    @update:modelValue="
                      changeDate($event, 'tanggal_pembayaran')
                    "
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.tanggal_pembayaran"
                  >
                    {{ formError.tanggal_pembayaran }}
                  </div>
                </div>
              </div>

              <div class="col-6" v-if="piutangPajak.sisa_pajak > 0">
                <div class="form-group">
                  <label>Akun Piutang Pajak</label>
                  <input type="text" class="form-control" disabled :value="piutangPajak.nama_akun">
                  <div class="form-error">
                    Sisa Pajak : {{ formatMoney(piutangPajak.sisa_pajak) }}
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="piutangPajak.sisa_pajak > 0">
                <div class="form-group">
                  <label>Nominal Pembayaran Piutang Pajak</label>
                  <input
                    type="text"
                    class="form-control text-right"
                    :readonly="!isEditable"
                    placeholder="Masukan nominal pembayaran piutang pajak"
                    v-money="configMoney"
                    @keyup="formChange('nilai_pajak')"
                    :class="{ 'is-invalid': formError && formError.nilai_pajak }"
                    v-model.lazy="formData.nilai_pajak"
                  />
                  <div class="form-error" v-if="formError && formError.nilai_pajak">
                    {{ formError.nilai_pajak }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Nomor Referensi :</label>
                  <input
                    type="text"
                    :class="{
                      'is-invalid': formError && formError.no_referensi,
                    }"
                    @keyup="formChange('no_referensi')"
                    class="form-control"
                    v-model="formData.no_referensi"
                    placeholder="Masukkan Nomor Referensi"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.no_referensi"
                  >
                    {{ formError.no_referensi }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Keterangan</label>
                  <textarea
                    rows="3"
                    placeholder="Masukan Keterangan"
                    v-model="formData.keterangan"
                    class="form-control"
                  ></textarea>
                </div>
              </div>

              <div class="col-6" v-if="false">
                <div class="form-group">
                  <label>Nominal Pembayaran</label>
                  <input
                    type="text"
                    class="form-control text-right"
                    :readonly="!isEditable"
                    placeholder="Masukan Nominal Pembayaran"
                    v-money="configMoney"
                    @keyup="formChange('nilai')"
                    :class="{ 'is-invalid': formError && formError.nilai }"
                    v-model.lazy="formData.nilai"
                  />
                  <div class="form-error" v-if="formError && formError.nilai">
                    {{ formError.nilai }}
                  </div>
                </div>
              </div>

              <div class="col-6" v-if="false">
                <div class="form-group">
                  <label>Kas & Bank</label>
                  <Select2
                    v-model="formData.id_akun"
                    :options="OptionKasBank"
                    placeholder="Pilih akun perkiraan..."
                    @change="formChange('id_akun')"
                    @select="formChange('id_akun')"
                    :class="{ 'is-invalid': formError && formError.id_akun }"
                    :settings="{
                      templateResult: formatState,
                    }"
                  />
                  <div class="form-error" v-if="formError && formError.id_akun">
                    {{ formError.id_akun }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="title">Rincian Pembayaran</div>
                <div class="sub-title">Rincian yang ada pada Pembayaran</div>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div class="search">
                <div class="form-group" style="width: 400px">
                  <Select2
                    v-model="AccountEstimated"
                    :options="OptionAccountEstimated"
                    placeholder="Pilih akun perkiraan..."
                    :settings="{
                      templateResult: formatState,
                    }"
                    @change="myChangeEvent($event)"
                    @select="mySelectAccountEstimated($event)"
                  />
                </div>
              </div>
              <!-- <div class="card-total mb-3">
                <div
                  class="total-debit d-flex flex-column"
                  style="width: unset"
                >
                  <span>Nominal Pembayaran</span>
                  <span class="ml-auto">
                    Rp
                    {{ this.formData.nilai ? this.formData.nilai : 0 }}</span
                  >
                </div>
              </div> -->
            </div>
            <div v-if="formError && formError.nilaiData">
              <div
                class="form-error"
                :style="{ marginBottom: '5px', fontSize: '15px' }"
              >
                {{ formError.nilaiData }}
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-bordered" id="tableDepartment">
                <thead>
                  <tr>
                    <th style="border-bottom: 1px solid #eceff1; width: 15%">
                      Akun
                    </th>
                    <th style="border-bottom: 1px solid #eceff1; width: 20%">
                      Nama Akun
                    </th>
                    <th v-if="false" style="border-bottom: 1px solid #eceff1; width: 15%">
                      Keterangan
                    </th>
                    <th style="border-bottom: 1px solid #eceff1; width: 20%">
                      Nilai
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, index) in formDetail"
                    :key="index"
                    @click="
                      editDetail(
                        index,
                        value.akun,
                        value.nama,
                        value.nilai,
                        value.keterangan,
                        $event
                      )
                    "
                    :style="{ cursor: 'pointer' }"
                  >
                    <!-- <tr v-for="(value, index) in formDetail" :key="index"> -->
                    <td>
                      {{ value.akun }}
                    </td>
                    <td>
                      {{ value.nama }}
                    </td>
                    <td v-if="false">
                      {{
                        value.keterangan && value.keterangan != ""
                          ? value.keterangan
                          : "-"
                      }}
                    </td>
                    <td class="text-right position-relative">
                      <div>
                        <span class="d-inline-block mr-3">{{
                          formatMoney(value.nilai)
                        }}</span>
                        <img
                          src="/img/avatar/icon-delete.svg"
                          alt=""
                          class="btn-add-row"
                          style="
                            position: absolute;
                            right: 10px;
                            width: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="formDetail.length === 0">
                    <td colspan="3" class="text-center">
                      Akun belum terpilih
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2">
                      <div class="d-flex justify-content-end align-items-center">
                        <div class="mr-3">Diskon</div>
                        <div class="form-group mb-0" style="max-width: 400px">
                          <Select2
                            v-model="formData.akun_diskon"
                            :options="OptionAccountEstimated"
                            placeholder="Pilih akun perkiraan..."
                            :settings="{
                              templateResult: formatState,
                            }"
                            @change="myChangeEvent($event)"
                            @select="myChangeEvent($event)"
                            :class="{ 'is-invalid': formError && formError.akun_diskon }"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control text-right"
                        placeholder="Masukan Nominal Diskon"
                        v-money="configMoney"
                        v-model.lazy="formData.nilai_diskon"
                        @keyup="formChange('nilai_diskon')"
                        :class="{ 'is-invalid': formError && formError.nilai_diskon }"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right"><b>Nominal Pembayaran</b></td>
                    <td style="font-weight: bold;" class="text-right">
                      <span>
                        Rp
                        {{ this.formData.nilai ? this.formData.nilai : 0 }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import $ from "jquery";
import moment from "moment";
import "moment/locale/id";
import { ref } from "vue";
import { loadScript } from "vue-plugin-load-script";
import Datepicker from "vue3-date-time-picker";
import Select2 from "vue3-select2-component";
import { get_AkunList } from "../../../actions/akun_perkiraan";
import NavbarDashboard from "../../../components/Navbar.vue";
import SidebarDashboard from "../../../components/Sidebar.vue";
import { checkRules, cksClient, showAlert } from "../../../helper";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");

import { maska } from "maska";
import { VMoney } from "v-money";
import {
  get_PiutangInvoiceGet,
  post_PiutangSavePembayaran,
} from "../../../actions/piutang";

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
  },

  directives: {
    money: VMoney,
    maska,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // this.FormatState()
    //  this.myChangeEvent()
    //  this.mySelectEvent()
  },

  data() {
    return {
      myValue: "aaaa",
      AccountEstimated: "",
      OptionAccountEstimated: [],
      id: "0",
      kodeProject: this.$route.params.kode_project
        ? this.$route.params.kode_project
        : "",
      id_company: cksClient().get("_account").id_company,
      no_invoice: this.$route.params.id,
      formModal: {
        key: "",
        id: "",
        nama: "",
        nilai: "",
        keterangan: "",
      },
      onModal: false,
      formDetail: [],
      bebanAkun: {
        nama: "",
        id: "",
      },
      formError: [],
      formData: {
        no_transaksi: "",
        no_invoice: this.$route.params.id ? this.$route.params.id : "",
        tanggal_pembayaran: "",
        no_referensi: "",
        status: "aktif",
        nilai: 0,
        nilaiData: 0,
        id_akun: "",
        keterangan: "",
        nilai_pajak: 0,
        nilai_diskon: 0,
        akun_diskon: "",
      },
      piutangPajak: {
        id_akun: "",
        nama_akun: "",
        sisa_pajak: 0
      },
      isGreather: false,
      sisa_pembayaran: 0,
      rules: {
        no_invoice: {
          required: true,
        },
        no_referensi: {
          required: false,
        },
        tanggal_pembayaran: {
          required: true,
        },
        id_akun: {
          required: true,
        },
        // nilai: {
        //   required: true,
        //   max: 0,
        //   msg_ax: "Nominal pembayaran tidak boleh melebihi dari sisa pembayaran"
        // },
        nilai_pajak: {
          required: false,
          max: 0,
          msg_ax: "Nominal pembayaran piutang pajak tidak boleh melebihi dari sisa piutang pajak"
        },
        akun_diskon: {
          required: false,
        }
      },
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      isSubmit: false,
      isEditable: true,
      isAkunBank: false,
      OptionKasBank: [],
      id_divisi: "",
      accessDepartement: cksClient().get("_account").akses_departement,
      maxTanggal: {
        min: new Date(),
        max: new Date(),
      },
    };
  },

  created() {
    this.getData();
    this.getKasBank(2, true);
  },
  methods: {
    getData() {
      get_PiutangInvoiceGet(
        this.no_invoice,
        (res) => {
          if (!res.is_success) {
            showAlert(this.$swal, {
              title: "WARNING!",
              msg: "Data Invoice tidak ditemukan",
              showCancelButton: true,
              showConfirmButton: false,
              onCancel: () => {
                this.$router.push({
                  name: "DataCredit",
                });
              },
            });
          }
          this.maxTanggal.min = moment(res.data.tanggal).format("YYYY-MM-DD");
          this.maxTanggal.max = moment(res.data.due_date).format("YYYY-MM-DD");
          this.formData.tanggal_pembayaran = this.maxTanggal.min;
          this.id_divisi = res.data.id_divisi;
          this.getKasBank(1);
          this.sisa_pembayaran = res.data.sisa_pembayaran;
          this.piutangPajak.id_akun = res.akun_piutangpajak && res.akun_piutangpajak.id_akun ? res.akun_piutangpajak.id_akun : "";
          this.piutangPajak.nama_akun = res.akun_piutangpajak && res.akun_piutangpajak.nama_akun ? res.akun_piutangpajak.nama_akun : "";
          this.piutangPajak.sisa_pajak = res.data.sisa_pajak && res.data.sisa_pajak ? res.data.sisa_pajak : 0;
          this.rules.nilai_pajak.max = this.piutangPajak.sisa_pajak;
          this.formData.nilai = 0;
          this.formData.nilaiData = this.formatMoney(res.data.nilai);
          if (res.data.akun_diskon) {
            this.formData.akun_diskon = res.data.akun_diskon;
          }
          // this.rules.nilai.max = this.formatMoney(res.data.sisa_pembayaran);
          this.bebanAkun.nama = res.data.nama_akun;
          this.bebanAkun.id = res.data.beban_akun;
          this.id = res.data.id_piutang;
          this.formDetail = [];
        },
        () => {
          showAlert(this.$swal, {
            title: "WARNING!",
            msg: "Data Invoice tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: true,
            onCancel: () => {
              this.$router.push({
                name: "DataCredit",
              });
            },
          });
        }
      );
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    //MAS
    getKasBank(tipe = "", notType = false) {
      var id_divisi =
        this.id_divisi && this.accessDepartement ? this.id_divisi : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
          not_type: notType,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          // console.log(data);
          if ((tipe == 2 || tipe == 1) && notType == false) {
            this.OptionKasBank = data;
          } else {
            this.OptionAccountEstimated = data;
          }
        }
      );
    },
    changeDate(data, key) {
      if (data) {
        this.formData[key] = moment(data).format("YYYY-MM-DD");
      } else {
        this.formData[key] = "";
      }
      this.formChange(key);
    },
    async formChange(val) {
      if (Object.hasOwnProperty.call(this.rules, val)) {
        this.rules[val].changed = true;
      }
      if (val == "nilai_pajak" || val == "nilai_diskon") {
        this.countDetail();
      }
      
      if (val == "nilai_diskon") {
        var value = this.formData[val] ? parseInt(this.formData[val].split(".").join("")) : 0;
        if (value > 0) {
          this.rules.akun_diskon.required = true;
        } else {
          this.rules.akun_diskon.required = false;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    saveDetail() {
      if (this.formModal.nilai) {
        if (this.formModal.key !== "") {
          this.formDetail[this.formModal.key] = {
            akun: this.formModal.id,
            nama: this.formModal.nama,
            keterangan: this.formModal.keterangan,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          };
        } else {
          this.formDetail.push({
            akun: this.formModal.id,
            nama: this.formModal.nama,
            keterangan: this.formModal.keterangan,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          });
        }
        this.countDetail();
        $("#modalAccountEstimated").modal("hide");
      }
    },
    editDetail(key, id, nama, nilai, keterangan, ev) {
      if (ev.target && ev.target.className === "btn-add-row") {
        this.deleteDetail(key);
      } else {
        this.formModal.key = key;
        this.formModal.id = id;
        this.formModal.nilai = nilai;
        this.formModal.nama = nama;
        this.formModal.keterangan = keterangan;
        var akun = this.OptionAccountEstimated.find((e) => e.id == id)
        this.isAkunBank = false;
        if (akun.type == "Kas dan Bank") {
          this.isAkunBank = true;
        }
        $("#modalAccountEstimated").modal("show");
      }
    },
    deleteDetail(key) {
      delete this.formDetail[key];
      this.formDetail.length -= 1;
      this.countDetail();
    },
    countDetail() {
      var nilaiDiskon = parseInt(this.formData.nilai_diskon.split(".").join("")) ? parseInt(this.formData.nilai_diskon.split(".").join("")) : 0;
      var nilai = 0;
      for (const key in this.formDetail) {
        if (Object.hasOwnProperty.call(this.formDetail, key)) {
          const element = this.formDetail[key];
          nilai += parseInt(element.nilai.split(".").join(""));
        }
      }
      this.formData.nilai = this.formatMoney(nilai + nilaiDiskon);
      
      this.formChange();
    },
    getTypeAkun(id) {
      var item = this.OptionAccountEstimated.find((e) => e.id == id);
      return item && item.type ? item.type : "";
    },
    mySelectAccountEstimated(ev) {
      if (ev.id) {
        var showModal = true;
        this.isAkunBank = false;
        if (ev.type == "Kas dan Bank") {
          this.isAkunBank = true;
          var item = this.formDetail.find(
            (e) => this.getTypeAkun(e.akun) == ev.type
          );
          if (item) {
            showModal = false;
          } else {
            this.formData.id_akun = ev.id;
          }
        }
        if (showModal) {
          this.onModal = true;
          this.formModal.key = "";
          this.formModal.id = ev.id;
          this.formModal.nilai = "";
          this.formModal.keterangan = "";
          this.formModal.nama = ev.text;
          $("#modalAccountEstimated").modal("show");
        } else {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Akun dengan type Kas dan Bank sudah terpilih, anda tidak bisa memilih lagi",
            showConfirmButton: false,
          });
        }

        setTimeout(() => {
          this.AccountEstimated = "";
        }, 500);
      }
    },
    postData() {
      var dataForm = this.formData;
      dataForm.nilai = this.formData.nilai.toString().split(".").join("");
      dataForm.nilai_pajak = this.formData.nilai_pajak ? this.formData.nilai_pajak.toString().split(".").join("") : 0;
      dataForm.nilai_diskon = this.formData.nilai_diskon ? this.formData.nilai_diskon.toString().split(".").join("") : 0;
      dataForm.jurnal_data = [...this.formDetail];
      dataForm.sisa_pembayaran = parseInt(this.sisa_pembayaran
        .toString()
        .split(".")
        .join("")) + this.piutangPajak.sisa_pajak;
      dataForm.akun_pajak = this.piutangPajak.id_akun
      this.isSubmit = true;
      post_PiutangSavePembayaran(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data pembayaran piutang berhasil dicatat"
              : "Data pembayaran piutang berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              if (this.kodeProject) {
                localStorage.setItem("invoiceTab", true);
                this.$router.push({
                  name: "DetailProject",
                  params: {
                    kode: this.kodeProject,
                  },
                });
              } else {
                localStorage.setItem("pembayaranSet", true);
                this.$router.push({
                  name: "DetailCredit",
                  params: {
                    id: this.id,
                  },
                });
              }
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      // this.formData.nilai = this.formatMoney(this.formData.nilai);
      var pembayaranPajak = parseInt(this.formData.nilai_pajak.toString().split('.').join(''));
      var nilaiPembayaran = parseInt(this.formData.nilai.toString().split('.').join(''))
      var sisaAllPembayaran = this.sisa_pembayaran + this.piutangPajak.sisa_pajak;
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;

      var item = this.formDetail.find((e) => this.getTypeAkun(e.akun) == "Kas dan Bank")
      var checkKas = item ? true : false;
      var checkPajak = true;
      
      if ((nilaiPembayaran == sisaAllPembayaran || nilaiPembayaran > this.sisa_pembayaran) && this.piutangPajak.sisa_pajak > 0 && pembayaranPajak == 0) {
        checkPajak = false;
      }
      if (check.success && checkKas && nilaiPembayaran <= sisaAllPembayaran && checkPajak) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (nilaiPembayaran >= sisaAllPembayaran && checkPajak) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Nominal pembayaran tidak boleh melebihi dari sisa pembayaran",
            showCancelButton: false,
            showConfirmButton: false,
          });
        } else if (!checkPajak) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Silakan masukkan nominal pembayaran piutang pajak",
            showCancelButton: false,
            showConfirmButton: false,
          });
        } else if (check.success && !checkKas) {
            showAlert(this.$swal, {
              title: "PERHATIAN!",
              msg: "Akun dengan tipe Kas dan Bank harus dipilih",
              showCancelButton: false,
              showConfirmButton: false,
            });  
        }
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}
</style>
